import angular from 'angular';

/**
 * @return {angular.IDirective} The Directive Definition Object.
 * @ngdoc directive
 * @ngInject
 */
const searchLoading = function () {
  return {
    link: (scope, element) => {
      //show spinning gif while waiting for the results
      // on the closest span from the input in which it is being typed
      element.on('typeahead:asyncrequest', () => {
        element.find('.twitter-typeahead').addClass('search-loading');
      });
      // on results received or canceled -> remove the loading spiner
      element.on('typeahead:asynccancel typeahead:asyncreceive', () => {
        // sometimes the classes are not removed/added in the correct order
        // and jQuery thinks there is nothing to remove when called immediately. Sic!
        setTimeout(() => {
          jQuery('.search-loading').removeClass('search-loading');
        }, 50);
      });
    },
  };
};

/**
 * @type {angular.IModule}
 * @hidden
 */
const gmfModule = angular.module('searchLoading', []);

gmfModule.directive('searchLoading', searchLoading);

export default gmfModule;
