import angular from 'angular';
import ngeoMapRecenter from 'ngeo/map/recenter.js';

/**
 * @private
 */
class Controller {
  /**
   * @ngInject
   */
  constructor() {
    // Binding properties
    /**
     * @type {string}
     * @export
     */
    this.floor;

    /**
     * @type {!ol.Map}
     * @export
     */
    this.map;
  }
}

/**
 * @hidden
 */
const gmfModule = angular.module('epflShortcuts', [ngeoMapRecenter.name]);

gmfModule.component('epflShortcuts', {
  bindings: {
    floor: '=',
    map: '<',
  },
  controller: Controller,
  template: () => require('./shortcuts.html'),
});

export default gmfModule;
