import angular from 'angular';

/**
 * @private
 */
class Controller {
  /**
   * @param {angular.gettext.gettextCatalog} gettextCatalog Gettext catalog.
   * @ngInject
   */
  constructor(gettextCatalog) {
    // Binding properties

    /**
     * @type {string}
     * @export
     */
    this.floor;

    /**
     * @type {ol.Map}
     * @export
     */
    this.map;

    /**
     * @type {{line: ol.Feature, steps: Array.<ol.Feature>}|null}
     * @export
     */
    this.roadmap;

    // Inner properties

    /**
     * @type {boolean}
     * @export
     */
    this.collapsed;

    /**
     * @type {number}
     * @export
     */
    this.stepIndex = 0;

    // allow angular-gettext-tools to collect the strings to translate
    gettextCatalog.getString('stairs');
    gettextCatalog.getString('lift');
    gettextCatalog.getString('ramp');
  }

  /**
   * @export
   */
  close() {
    this.roadmap = null;
    this.stepIndex = 0;
  }

  /**
   * @param {number} delta Delta.
   * @export
   */
  changeStep(delta) {
    this.stepIndex = this.stepIndex + delta;
    const steps = this.roadmap['steps'];
    const min = 0;
    const max = steps.length - 2;
    if (this.stepIndex < min) {
      this.stepIndex = max;
    } else if (this.stepIndex > max) {
      this.stepIndex = min;
    }
    const step = steps[this.stepIndex];
    if (step) {
      const view = this.map.getView();
      if (this.stepIndex == 0) {
        // recenter to the whole routing result
        const size = /** @type {ol.Size} */ (this.map.getSize());
        view.fit(this.roadmap['line'].getGeometry(), {size});
      } else {
        view.setCenter(step.getGeometry().getCoordinates());
        view.setZoom(12);
      }
      this.floor = step.get('level').toString();
    }
  }
}

/**
 * @hidden
 */
const gmfModule = angular.module('epflRoutingRoadmap', []);

gmfModule.component('epflRoutingRoadmap', {
  bindings: {
    floor: '=',
    map: '=',
    roadmap: '=',
    collapsed: '=',
  },
  controller: Controller,
  template: () => require('./routingroadmap.html'),
});

export default gmfModule;
