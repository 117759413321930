import angular from 'angular';
import gmfPrintComponent from 'gmf/print/component.js';

/**
 * @enum {string}
 */
const PrintStateEnum = {
  /**
   * @type {string}
   */
  PRINTING: 'printing',

  /**
   * @type {string}
   */
  CAPABILITIES_NOT_LOADED: 'capabilitiesNotLoaded',

  /**
   * @type {string}
   */
  ERROR_ON_GETCAPABILITIES: 'errorOnGetCapabilities',
};

/**
 * @private
 */
class Controller {
  /**
   * @param {angular.IScope} $rootScope The directive's scope.
   * @param {angular.IScope} $scope Scope.
   * @param {PrintState} gmfPrintState GMF print state.
   * @ngInject
   */
  constructor($rootScope, $scope, gmfPrintState) {
    // Binding properties

    /**
     * @type {boolean}
     * @export
     */
    this.active;

    /**
     * @type {!ol.Map}
     * @export
     */
    this.map;

    /**
     * @type {boolean}
     * @export
     */
    this.panelVisible;

    /**
     * @type {boolean}
     * @export
     */
    this.printAreaSelected;

    // Injected properties

    /**
     * @type {angular.IScope}
     * @private
     */
    this.$rootScope_ = $rootScope;

    /**
     * @type {angular.IScope}
     * @private
     */
    this.$scope_ = $scope;

    /**
     * @type {PrintState}
     * @private
     */
    this.gmfPrintState_ = gmfPrintState;

    // Inner properties

    /**
     * @type {boolean}
     * @export
     */
    this.areaSelected = false;
  }

  /**
   * Called on initialization of the controller.
   */
  $onInit() {
    this.$scope_.$watch(
      () => this.printAreaSelected,
      (value) => {
        this.areaSelected = value;
      }
    );
  }

  /**
   * Test the printing state.
   * @return {boolean} True if the current state of the print is "PRINTING".
   * @export
   */
  isPrinting() {
    return this.gmfPrintState_.state === PrintStateEnum.PRINTING;
  }

  /**
   * Test the state of the print to now if we can display the print panel.
   * @return {boolean} True if the current state permits to display the print
   *     panel.
   * @export
   */
  showPrint() {
    return (
      this.gmfPrintState_.state !== PrintStateEnum.CAPABILITIES_NOT_LOADED &&
      this.gmfPrintState_.state !== PrintStateEnum.ERROR_ON_GETCAPABILITIES
    );
  }

  /**
   * Close the rightPanel to select the area.
   * @export
   */
  selectArea() {
    this.panelVisible = false;
  }

  /**
   * Broadcast a 'gmfStartPrint' event to run a 'pdf' print.
   * @export
   */
  print() {
    this.$rootScope_.$broadcast('gmfStartPrint', 'pdf');
  }

  /**
   * Broadcast a 'gmfCancelPrint' event to cancel the current print task.
   * @export
   */
  cancel() {
    this.$rootScope_.$broadcast('gmfCancelPrint');
  }
}

/**
 * @hidden
 */
const gmfModule = angular.module('epflPrint', [gmfPrintComponent.name]);

gmfModule.component('epflPrint', {
  bindings: {
    active: '=',
    map: '<',
    panelVisible: '=',
    printAreaSelected: '=',
  },
  controller: Controller,
  template: () => require('./print.html'),
});

export default gmfModule;
