import angular from 'angular';
import * as olEvents from 'ol/events.js';
import olObservable from 'ol/Observable.js';

/**
 * @private
 */
class Controller {
  /**
   * @param {JQuery} $element Element.
   * @param {angular.IScope} $scope Angular scope.
   * @ngInject
   */
  constructor($element, $scope) {
    // Binding properties

    /**
     * @type {string}
     * @export
     */
    this.floor;

    /**
     * @type {ol.Map}
     * @export
     */
    this.map;

    // Injected properties

    /**
     * @type {!jQuery}
     * @private
     */
    this.element_ = $element;

    /**
     * @type {!angular.Scope}
     * @private
     */
    this.scope_ = $scope;

    // Inner properties

    /**
     * @type {Array.<ol.EventsKey>}
     * @private
     */
    this.listenerKeys_ = [];

    /**
     * @type {Array.<Object.<string, string>>}
     * @export
     */
    this.values = [
      {
        value: '-4',
        label: '-4',
      },
      {
        value: '-3',
        label: '-3',
      },
      {
        value: '-2',
        label: '-2',
      },
      {
        value: '-1',
        label: '-1',
      },
      {
        value: '0',
        label: '0',
      },
      {
        value: '1',
        label: '1',
      },
      {
        value: '2',
        label: '2',
      },
      {
        value: '3',
        label: '3',
      },
      {
        value: '4',
        label: '4',
      },
      {
        value: '5',
        label: '5',
      },
      {
        value: '6',
        label: '6',
      },
      {
        value: '7',
        label: '7',
      },
      {
        value: '8',
        label: '8',
      },
      {
        value: '99',
        label: '+',
      },
    ];

    /**
     * @type {number|undefined}
     * @export
     */
    this.zoom;
  }

  /**
   * Called on initialization of the controller.
   */
  $onInit() {
    this.scope_.$watch(
      () => this.zoom,
      (zoom) => {
        if (zoom > 7) {
          this.element_.show(0.3);
        } else {
          this.element_.hide(0.3);
        }
      }
    );

    this.listenerKeys_.push(olEvents.listen(this.element_[0], 'wheel', this.handleElementWheel_, this));

    let lastFloor = this.floor;
    let lastZoom;

    // Export a zoom property and force dimension to 99 on low zoom levels
    // like in old plan.
    this.scope_.$watch(
      () => this.map.getView().getResolution(),
      () => {
        const currentZoom = Math.floor(this.map.getView().getZoom());
        this.zoom = currentZoom;
        if (currentZoom !== undefined) {
          const changedToSmallZoom = currentZoom <= 7 && (lastZoom === undefined || lastZoom > 7);
          const changedToBigZoom = currentZoom > 7 && (lastZoom === undefined || lastZoom <= 7);

          if (changedToSmallZoom) {
            lastFloor = this.floor;
            this.floor = '99';
          }

          if (changedToBigZoom && this.floor == '99') {
            this.floor = lastFloor;
          }
          lastZoom = currentZoom;
        }
      }
    );
  }

  /**
   * @param {WheelEvent} event Event.
   * @private
   */
  handleElementWheel_(event) {
    this.scope_.$apply(() => {
      const delta = event.deltaY > 0 ? -1 : 1;
      this.changeFloor(delta);
    });
  }

  /**
   * @return {number} The index of the current floor in the list.
   * @private
   */
  getFloorIndex_() {
    const floor = this.floor;
    return this.values.findIndex((item) => {
      const isFloor = item.value === floor;
      return isFloor;
    });
  }

  /**
   * @param {number} delta 1 or -1.
   * @export
   */
  changeFloor(delta) {
    console.assert(Math.abs(delta) == 1);
    const index = this.getFloorIndex_();
    console.assert(index > -1);

    const newindex = index + delta;
    if (newindex >= 0 && newindex < this.values.length) {
      this.floor = this.values[newindex].value;
    }
  }

  /**
   * @param {number} delta 1 or -1.
   * @return {string} Label
   * @export
   */
  getLabel(delta) {
    const index = this.getFloorIndex_();
    console.assert(index > -1);

    const newindex = index + delta;
    if (newindex >= 0 && newindex < this.values.length) {
      return this.values[newindex].label;
    } else {
      return '';
    }
  }

  /**
   * Called on destruction of the controller.
   */
  $onDestroy() {
    // Unregister OL events
    olObservable.unByKey(this.listenerKeys_);
    this.listenerKeys_.length = 0;
  }
}

/**
 * @hidden
 */
const gmfModule = angular.module('epflFloor', []);

gmfModule.component('epflFloor', {
  bindings: {
    'floor': '=',
    'map': '<',
  },
  controller: Controller,
  template: () => require('./floor.html'),
});

export default gmfModule;
