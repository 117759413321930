import angular from 'angular';

/**
 * @ngInject
 * @return {angular.IDirective} The Directive Definition Object.
 * @ngdoc directive
 */
const bodyCssToggler = function () {
  return {
    link: (scope, element) => {
      element.on('click', () => {
        jQuery('body').toggleClass('epfl-header-closed epfl-header-open');
        jQuery(window).trigger('resize'); // force OL3 map to be resized
      });
    },
    template: '<div class="body-css-toggler fa fa-2x fa-angle-down"></div>',
  };
};

/**
 * @type {angular.IModule}
 * @hidden
 */
const gmfModule = angular.module('bodyCssToggler', []);

gmfModule.directive('bodyCssToggler', bodyCssToggler);

export default gmfModule;
