import angular from 'angular';
import gmfSearchComponent from 'gmf/search/component.js';
import ngeoStatemanagerLocation from 'ngeo/statemanager/Location.js';

/**
 * @private
 */
class Controller {
  /**
   * @param {JQuery} $element Element.
   * @param {angular.IScope} $scope Angular scope.
   * @param {angular.ITimeoutService} $timeout Angular timeout service.
   * @param {angular.auto.IInjectorService} $injector Angular injector service.
   * @param {import('gmf/options.js').gmfSearchGroups} gmfSearchGroups The groups.
   * @ngInject
   */
  constructor($element, $scope, $timeout, $injector, gmfSearchGroups) {
    // Binding properties

    /**
     * @type {string|undefined}
     * @export
     */
    this.floor;

    /**
     * @type {!ol.Map}
     * @export
     */
    this.map;

    /**
     * @type {string|undefined}
     * @export
     */
    this.query;

    /**
     * The selected search feature.
     * @type {ol.Feature}
     * @export
     */
    this.selected;

    /**
     * @type {string|undefined}
     * @export
     */
    this.optionsName;

    // Injected properties

    /**
     * @type {jQuery}
     * @private
     */
    this.element_ = $element;

    /**
     * @type {angular.IScope}
     * @private
     */
    this.scope_ = $scope;

    /**
     * @type {!angular.ITimeoutService}
     * @private
     */
    this.timeout_ = $timeout;

    /**
     * @type {angular.auto.IInjectorService}
     * @private
     */
    this.injector_ = $injector;

    /**
     * @type {import('gmf/options.js').gmfSearchGroups}
     * @private
     */
    this.gmfSearchGroups_ = gmfSearchGroups;

    // Inner properties

    /**
     * @type {boolean}
     * @private
     */
    this.recenter_ = false;

    /**
     * On search item selection, change the floor level.
     * @type {import('ngeo/search/searchDirective.js').SearchDirectiveListeners<olFeature<import('ol/geom/Geometry.js').default>>}
     * @export
     */
    this.searchListeners = {
      select: (event, feature) => {
        this.selected = feature;
        let floor = feature.get('floor');
        if (floor === undefined) {
          const params = feature.get('params');
          if (params) {
            floor = params['floor'];
          }
        }
        if (this.floor !== undefined && floor !== undefined) {
          this.floor = floor.toString();
        }
      },
    };

    /**
     * Increase the search minimum length and keep highlight.
     * @type {TypeaheadOptions}
     * @export
     */
    this.typeaheadOptions = {
      minLength: 2,
      highlight: true,
    };

    /**
     * @type {string}
     * @export
     */
    this.inputValue = '';

    // Set routing target.
    const url = new URL(window.location.href);
    const routing = url.searchParams.get('routing');
    /**
     * @type {string}
     * @private
     */
    this.routing_ = routing === null ? 'validated' : routing;

    // Get search options.
    const config = this.optionsName || 'gmfSearchOptions';
    /**
     * @type {import('gmf/options.js').gmfSearchOptions}
     * @private
     */
    this.options_ = this.injector_.get(config);

    this.setBloodHoundOptions_();
  }

  /**
   * Called on initialization of the controller.
   */
  $onInit() {
    this.watchInputValue_();
    this.watchQueryUrl_();

    // Timeout with 200ms to wait enough on angular to create the gmf-search
    // component.
    this.timeout_(() => {
      this.manipulateSuggestions_();
    }, 200);
  }

  /**
   * Reset the selected feature when the input is empty (clear button).
   * @private
   */
  watchInputValue_() {
    this.scope_.$watch(
      () => this.inputValue,
      (value) => {
        if (!value) {
          this.selected = null;
        }
      }
    );
  }

  /**
   * Watch attribute query (set by the maincontroller when the user load the page with keywords in
   * the params of the url).
   * Set mode of the search if a "query" is set.
   * @private
   */
  watchQueryUrl_() {
    this.scope_.$watch(
      () => this.query,
      (query) => {
        if (query) {
          this.timeout_(() => {
            const input = this.element_.find('input');
            console.assert(input.length > 0);

            input.typeahead('activate');
            input.typeahead('val', query);
            // force an 'input' event to update the input ng-model value
            input.trigger('input');

            // recenter the view instead of highlighting the first result
            this.recenter_ = true;

            this.query = undefined;
          });
        }
      }
    );
  }

  /**
   * Increase the search timeout and add the wildcard on all provided datasources.
   * @private
   */
  setBloodHoundOptions_() {
    const bloodhoundOptions = /** @type {BloodhoundOptions} */ ({
      remote: {
        rateLimitWait: 250,
        prepare: (query, settings) => {
          // add the wildcard at the end of the query but not if the
          // query string come from an url param
          const wildcard = this.query ? '' : '*';
          settings.url += `&routing=${this.routing_}&query=${query}${wildcard}`;
          return settings;
        },
      },
    });

    // Add new option to the datasources.
    this.options_.datasources.forEach((datasource) => {
      const datasourceBloodhoundOptions = datasource.bloodhoundOptions || {};
      Object.assign(datasourceBloodhoundOptions, bloodhoundOptions);
      datasource.bloodhoundOptions = /** @type {BloodhoundOptions} */ (datasourceBloodhoundOptions);
    });
  }

  /**
   * On search results, if there is a unique result, select it or highlight it.
   * @private
   */
  manipulateSuggestions_() {
    const input = this.element_.find('input');
    console.assert(input.length > 0);

    // Count number of datasource. Keep the same logic as in the gmf-search component.
    let datasourcesCount = 0;
    this.options_.datasources.forEach((datasource) => {
      // Count one datasource per group value or groups.
      const groupValues =
        datasource.groupValues !== undefined ? datasource.groupValues : this.gmfSearchGroups_;
      datasourcesCount += groupValues.length === 0 ? 1 : groupValues.length;
      // Count one datasource per group action.
      const groupActions = datasource.groupActions || [];
      datasourcesCount += groupActions.length;
    });
    // Count one more datasource for the search coordinate datasource.
    datasourcesCount += 1;

    // Typeahead Issue: initially miss one asyncreceive
    let typeaheadIssue = 1;

    // If there's only one result, select it.
    // We need to wait for all the datasources to respond.
    let responseCount = 0;
    input.on('typeahead:asyncreceive', (event) => {
      responseCount++;
      if (responseCount === datasourcesCount - typeaheadIssue) {
        typeaheadIssue = 0;
        responseCount = 0;
        // Wait on the dropdown to be fulfilled.
        this.timeout_(() => {
          const suggestions = this.element_.find('.tt-menu .tt-suggestion');
          if (suggestions.length === 1) {
            if (this.recenter_) {
              // recenter
              suggestions.trigger('click');
            } else {
              // highlight result
              suggestions.addClass('tt-cursor');
            }
          }
          this.recenter_ = false;
        });
      }
    });
  }
}

/**
 * @hidden
 */
const gmfModule = angular.module('epflSearch', [gmfSearchComponent.name, ngeoStatemanagerLocation.name]);

gmfModule.component('epflSearch', {
  bindings: {
    floor: '=?',
    map: '<',
    query: '=?',
    selected: '=?',
    optionsName: '@',
  },
  controller: Controller,
  template: () => require('./search.html'),
});

export default gmfModule;
