import angular from 'angular';
import epfl_authgeoportailSearchComponent from '../search/component.js';
import ngeoMapFeatureOverlayMgr from 'ngeo/map/FeatureOverlayMgr';
import {MessageType} from 'ngeo/message/Message';
import ngeoMessageNotification from 'ngeo/message/Notification';
import * as olBase from 'ol/index.js';
import olMap from 'ol/Map.js';
import olFormatGeoJSON from 'ol/format/GeoJSON.js';
import olGeomSimpleGeometry from 'ol/geom/SimpleGeometry.js';
import olStyleCircle from 'ol/style/Circle.js';
import olStyleFill from 'ol/style/Fill.js';
import olStyleStyle from 'ol/style/Style.js';
import olStyleStroke from 'ol/style/Stroke.js';
import olStyleIcon from 'ol/style/Icon.js';

/**
 * @private
 */
class Controller {
  /**
   * @param {angular.IHttpService} $http Angular $http service.
   * @param {angular.IScope} $scope Angular scope.
   * @param {angular.gettext.gettextCatalog} gettextCatalog Gettext catalog.
   * @param {string} routingUrl
   * @param {string} assetsBaseUrl
   * @ngInject
   */
  constructor($http, $scope, gettextCatalog, routingUrl, assetsBaseUrl) {
    // Binding properties

    /**
     * @type {ol.Feature|undefined}
     * @export
     */
    this.from;

    /**
     * @type {string|undefined}
     * @export
     */
    this.fromQuery;

    /**
     * @type {boolean}
     * @export
     */
    this.initialized;

    /**
     * @type {ol.Map}
     * @export
     */
    this.map;

    /**
     * @type {{line: ol.Feature, steps: Array.<ol.Feature>}|null}
     * @export
     */
    this.roadmap;

    /**
     * @type {ol.Feature|undefined}
     * @export
     */
    this.to;

    /**
     * @type {string|undefined}
     * @export
     */
    this.toQuery;

    /**
     * @type {string|undefined}
     * @export
     */
    this.floor;

    /**
     * @type {string|undefined}
     * @export
     */
    this.optionsName;

    // Injected properties

    /**
     * @type {angular.IHttpService}
     * @private
     */
    this.http_ = $http;

    /**
     * @type {angular.IScope}
     * @private
     */
    this.$scope_ = $scope;

    /**
     * @type {angular.gettext.gettextCatalog}
     * @private
     */
    this.gettextCatalog_ = gettextCatalog;

    /**
     * @type {import("ngeo/message/Notification").MessageNotification}
     * @private
     */
    this.ngeoNotification_ = ngeoMessageNotification;

    /**
     * @type {string}
     * @private
     */
    this.routingUrl_ = routingUrl;

    /**
     * @type {string}
     * @private
     */
    this.routing_;

    /**
     * @type {integer}
     * @private
     */
    this.priority_stairs_;

    // Inner properties

    /**
     * Fake ol.Map passed to the search directive instead of the
     * real one because we don't want any highlight or recentering.
     * @type {ol.Map}
     * @export
     */
    this.dummyMap = new olMap({});

    /**
     * @type {ol.style.Style}
     * @private
     */
    this.lineStyle_ = new olStyleStyle({
      stroke: new olStyleStroke({
        color: [51, 153, 204],
        width: 3,
      }),
    });

    const whiteCircle = new olStyleStyle({
      image: new olStyleCircle({
        radius: 12,
        fill: new olStyleFill({
          color: [255, 255, 255],
        }),
        stroke: new olStyleStroke({
          width: 2,
          color: [211, 211, 211],
        }),
      }),
    });

    /**
     * @type {Array.<ol.style.Style>}
     * @private
     */
    this.fromStyle_ = [
      whiteCircle,
      new olStyleStyle({
        image: new olStyleIcon({
          src: `${assetsBaseUrl}images/routing/depart_red.svg`,
        }),
      }),
    ];

    /**
     * @type {Array.<ol.style.Style>}
     * @private
     */
    this.toStyle_ = [
      whiteCircle,
      new olStyleStyle({
        image: new olStyleIcon({
          src: `${assetsBaseUrl}images/routing/destination_red.svg`,
        }),
      }),
    ];

    /**
     * @type {Array.<ol.style.Style>}
     * @private
     */
    this.notDefinedStyle_ = [new olStyleStyle({})];

    /**
     * @type {import('ngeo/map/FeatureOverlay').FeatureOverlay}
     * @private
     */
    this.featureOverlay_ = ngeoMapFeatureOverlayMgr.getFeatureOverlay();

    this.featureOverlay_.setStyle((feature, resolution) => {
      const type = feature.get('type');
      if (!type) {
        return this.lineStyle_;
      } else if (type === 'start') {
        return this.fromStyle_;
      } else if (type === 'end') {
        return this.toStyle_;
      } else {
        return this.notDefinedStyle_;
      }
    });
  }

  /**
   * Called on initialization of the controller.
   */
  $onInit() {
    // set routing target
    const url = new URL(window.location.href);
    const routing = url.searchParams.get('routing');
    this.routing_ = routing === null ? 'validated' : routing;

    const priority_stairs = url.searchParams.get('priority_stairs');
    this.priority_stairs_ = priority_stairs === null ? 0 : priority_stairs;

    // auto submit the form if the directive is initialized with query terms.
    // (`from` and `to` are present in the url params)
    if (this.fromQuery && this.toQuery) {
      const unbind = this.$scope_.$watch(
        () => !!(this.from && this.to),
        (value) => {
          if (value) {
            this.submit();
            unbind();
          }
        }
      );
    }

    // clear the routing result
    this.$scope_.$watch(
      () => !this.from || !this.to || !this.roadmap,
      (value) => {
        if (value) {
          this.clearResults_();
        }
      }
    );

    this.initialized = true;
  }

  /**
   * @export
   */
  submit() {
    if (this.from && this.to) {
      this.featureOverlay_.clear();
      const config = {
        params: {
          action: 'routing',
          from: this.from.get('vertex_id'),
          to: this.to.get('vertex_id'),
          routing: this.routing_,
          priority_stairs: this.priority_stairs_,
        },
      };
      const request = this.http_.get(this.routingUrl_, config);
      request.then(this.routingSuccess_.bind(this), this.routingError_.bind(this));
    }
  }

  /**
   * @param {Object} response Response (FIXME).
   * @private
   */
  routingSuccess_(response) {
    const parser = new olFormatGeoJSON();
    const readOptions = {
      dataProjection: 'EPSG:2056',
      featureProjection: 'EPSG:2056',
    };
    const line = parser.readFeature(response.data.feature, readOptions);

    // openlayers doesn't like id === null
    line.setId(line.getId() === null ? olBase.getUid(line) : line.getId());
    this.featureOverlay_.addFeature(line);

    const geometry = line.getGeometry();
    const size = this.map.getSize();
    console.assert(geometry instanceof olGeomSimpleGeometry);
    console.assert(size instanceof Array);
    this.map.getView().fit(geometry, size);

    const steps = [];
    for (let i = 0; i < response.data['roadmap'].length; i++) {
      const step = response.data['roadmap'][i];
      const feature = parser.readFeature(step, readOptions);
      steps.push(feature);
      this.featureOverlay_.addFeature(feature);
    }

    // add the labels to the start and end points (used in the roadmap)
    const start = steps[0];
    console.assert(start.get('type') === 'start');
    const end = steps[steps.length - 1];
    console.assert(end.get('type') === 'end');
    start.set('label', this.from.get('label'));
    end.set('label', this.to.get('label'));

    this.roadmap = {
      line: line,
      steps: steps,
    };

    // change floor to roadmap start floor
    this.floor = steps[0].get('level');
  }

  /**
   * @private
   */
  clearResults_() {
    this.featureOverlay_.clear();
    this.roadmap = null;
  }

  /**
   * @param {Object} response Response (FIXME).
   * @private
   */
  routingError_(response) {
    const gettextCatalog = this.gettextCatalog_;

    this.ngeoNotification_.notify({
      msg: gettextCatalog.getString('Routing failed'),
      type: MessageType.ERROR,
    });
  }
}

/**
 * @hidden
 */
const gmfModule = angular.module('epflRouting', [epfl_authgeoportailSearchComponent.name]);

gmfModule.component('epflRouting', {
  bindings: {
    from: '=?',
    fromQuery: '=?',
    initialized: '=?',
    map: '=',
    roadmap: '=?',
    to: '=?',
    toQuery: '=?',
    floor: '=?',
    optionsName: '@',
  },
  controller: Controller,
  template: () => require('./routing.html'),
});

export default gmfModule;
