import angular from 'angular';
import gmflayertreeTreeManager from 'gmf/layertree/TreeManager.js';
import ngeoLayertreeController from 'ngeo/layertree/Controller.js';

/**
 * @enum {string}
 */
export const LayertreeVisitorDecision = {
  SKIP: 'SKIP',
};

/**
 * @private
 */
class Controller {
  /**
   * @param {angular.IScope} $scope Angular scope.
   * @param {import("gmf/layertree/TreeManager.js").LayertreeTreeManager} gmfTreeManager gmf Tree Manager service.
   * @ngInject
   */
  constructor($scope, gmfTreeManager) {
    // Injected properties

    /**
     * @type {angular.IScope}
     * @private
     */
    this.scope_ = $scope;

    /**
     * @type {import("gmf/layertree/TreeManager.js").LayertreeTreeManager}
     * @private
     */
    this.gmfTreeManager_ = gmfTreeManager;

    // Inner properties

    /**
     * @type {Array.<ngeo.layertree.Controller>}
     * @export
     */
    this.selected;
  }

  /**
   * Called on initialization of the controller.
   */
  $onInit() {
    this.scope_.$on('ngeo-layertree-state', (map, treeCtrl, firstParent) => {
      this.populateSelected(this.gmfTreeManager_.rootCtrl);
    });

    this.scope_.$watchCollection(
      () => {
        const collection = this.gmfTreeManager_.rootCtrl ? this.gmfTreeManager_.rootCtrl.children : undefined;
        return collection;
      },
      (firstLayers) => {
        if (firstLayers) {
          this.populateSelected(this.gmfTreeManager_.rootCtrl);
        }
      }
    );
  }

  /**
   * @param {ngeo.layertree.Controller} ctrl Layer tree controller.
   * @export
   */
  setStateOff(ctrl) {
    ctrl.setState('off');
  }

  /**
   * @param {ngeo.layertree.Controller} rootCtrl Root layer tree controller
   * @export
   */
  populateSelected(rootCtrl) {
    const newSelected = [];

    const visitor = /** @type {ngeo.layertree.Controller.Visitor} */ (
      (treeCtrl) => {
        if (treeCtrl === rootCtrl) {
          return; // always enter first level groups
        }
        switch (treeCtrl.getState()) {
          case 'on':
            newSelected.push(treeCtrl);
            return LayertreeVisitorDecision.SKIP;
          case 'off':
            return LayertreeVisitorDecision.SKIP;
          default:
        }
      }
    );

    if (rootCtrl) {
      rootCtrl.traverseDepthFirst(visitor);
    }

    this.selected = newSelected;
  }
}

/**
 * @hidden
 */
const gmfModule = angular.module('epflSelectedLayers', [
  gmflayertreeTreeManager.name,
  ngeoLayertreeController.name,
]);

gmfModule.component('epflSelectedLayers', {
  controller: Controller,
  template: () => require('./selectedlayers.html'),
});

export default gmfModule;
